import get from "lodash/get";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";

export function getTaskForDocumentId(key, dossierDetails, documentId) {
  if (!dossierDetails) return;
  // return the most recently updated task with the specified key and documentId
  let query = {
    taskDefinitionKey: key,
    documentId: documentId.id,
  };
  return get(
    orderBy(filter(get(dossierDetails, "task"), query), "updatedAt", "desc"),
    0,
  );
}
