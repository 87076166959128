import React from "react";
import { get } from "lodash";

import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import dossierDefinitionKeys from "../../constants/dossierDefinitionKeys";
import HoppinV1Dossier from "./mowsubsidiesDossier";
import HoppinV2Dossier from "./mowHoppinDossier";
import HaltesDossier from "./mowHaltesDossier";
import SchoolomgevingenDossier from "./mowSchoolomgevingenDossier";
import VervoerOpMaatDossier from "./mowVervoerOpMaatDossier";
import ZeroEmissieDossier from "./mowZeroEmissieDossier";
import DefaultDossier from "./mowDefaultDossier";

import contextType from "@skryv/core-react/src/services/contextTypes";

export function mowDossierPageWrapper() {
  class mowDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    render() {
      const dossierDefinitionName = get(
        this.props.dossierDetails,
        ["dossier", "dossierDefinition", "key"],
        "",
      );
      switch (dossierDefinitionName) {
        case dossierDefinitionKeys.HOPPINV1:
          return <HoppinV1Dossier {...this.props} />;
        case dossierDefinitionKeys.HOPPINV2:
          return <HoppinV2Dossier {...this.props} />;
        case dossierDefinitionKeys.HALTES:
          return <HaltesDossier {...this.props} />;
        case dossierDefinitionKeys.SCHOOLOMGEVINGEN:
          return <SchoolomgevingenDossier {...this.props} />;
        case dossierDefinitionKeys.VERVOER_OP_MAAT:
          return <VervoerOpMaatDossier {...this.props} />;
        case dossierDefinitionKeys.ZERO_EMISSIE:
          return <ZeroEmissieDossier {...this.props} />;
        default:
          return <DefaultDossier {...this.props} />;
      }
    }
  }

  return mowDossierPage;
}

export default coreDossierPageWrapper(mowDossierPageWrapper());
