import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postscribePromise } from "../../services/postscribe";
import { fetchBurgerprofielConfiguration } from "../../store/actions";
import {
  hasLoadedBurgerprofiel,
  hasValidBurgerprofielFooter,
  selectBurgerprofielFooter,
} from "../../store/selectors";

export default function MowFooter() {
  const FOOTER_SELECTOR = "footer-script";
  const dispatch = useDispatch();
  const [hasInitializedScript, setHasInitializedScript] = useState(false);

  useEffect(() => {
    dispatch(fetchBurgerprofielConfiguration());
  }, [dispatch]);

  const embedCode = useSelector(selectBurgerprofielFooter);
  const hasLoadedConfiguration = useSelector(hasLoadedBurgerprofiel);
  const hasValidConfiguration = useSelector(hasValidBurgerprofielFooter);
  const loadBurgerprofielFooter = (selector, embedCode) => {
    const burgerprofielPolyfillSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";
    return postscribePromise(`#${selector}`, burgerprofielPolyfillSrc).then(
      () => postscribePromise(`#${selector}`, embedCode),
    );
  };

  useEffect(() => {
    console.log("hasLoadedConfiguration: " + hasLoadedConfiguration);
    console.log("hasInitializedScript: " + hasInitializedScript);
    console.log("hasValidConfiguration: " + hasValidConfiguration);
    if (!hasLoadedConfiguration) return;
    if (hasInitializedScript) return;
    if (!hasValidConfiguration) return;

    loadBurgerprofielFooter(FOOTER_SELECTOR, embedCode).then(() => {
      setHasInitializedScript(true);
    });
  }, [
    hasValidConfiguration,
    hasLoadedConfiguration,
    hasInitializedScript,
    embedCode,
  ]);

  return (
    <>
      <div id={FOOTER_SELECTOR}></div>
    </>
  );
}
