import React from "react";

import { connect } from "react-redux";

import CoreDashboardPage from "@skryv/core-react-vo/src/pages/Dashboard/DashboardPage";
import contactInformation from "../constants/contactInformation";

import {
  defaultProps,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import contextType from "@skryv/core-react/src/services/contextTypes";

import { fetchDossierCreationToggles } from "../../store/actions";
import {
  isAbleToCreateToegangkelijkeHaltesDossiers,
  isAbleToCreateHoppinPuntenDossiers,
  isAbleToCreateVeiligeSchoolOmgevingenDossiers,
  isAbleToCreateVervoerOpMaatDossiers,
  isAbleToCreateZeroEmissieDossiers,
} from "../../store/selectors";

const HOPPIN_PUNTEN_V1_DOSDEF_KEY = "hoppinpunten";
const HOPPIN_PUNTEN_DOSDEF_KEY = "subsidieHoppinpunten";
const TOEGANGKELIJKE_HALTES_DOSDEF_KEY = "subsidieToegankelijkeHaltes";
const VEILIGE_SCHOOLOMGEVINGEN_DOSDEF_KEY = "subsidieVeiligeSchoolomgevingen";
const VERVOER_OP_MAAT_DOSDEF_KEY = "subsidieVervoerOpMaat";
const ZERO_EMISSIE_DOSDEF_KEY = "subsidieZeroEmissieVoertuigen";

class mowsubsidiesDashboard extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    props.fetchDossierCreationToggles();

    this.state = {
      contactInformation,
      title: "Subsidies mobiliteitsbeleid",
      goToDossierInformationPageFirst: true,
    };

    console.log(props);
  }

  render() {
    return (
      <CoreDashboardPage
        {...this.state}
        createDossierInformation={this.props.createDossierInformation}
      ></CoreDashboardPage>
    );
  }
}

const mapStateToProps = (state) => {
  const canCreateHoppinPuntenDossiers =
    isAbleToCreateHoppinPuntenDossiers(state);
  const canCreateToegangkelijkeHaltesDossiers =
    isAbleToCreateToegangkelijkeHaltesDossiers(state);
  const canCreateVeiligeSchoolOmgevingDossiers =
    isAbleToCreateVeiligeSchoolOmgevingenDossiers(state);
  const canCreateVervoerOpMaatDossiers =
    isAbleToCreateVervoerOpMaatDossiers(state);
  const canCreateZeroEmissieDossiers = isAbleToCreateZeroEmissieDossiers(state);

  let createDossierInformation = [];

  if (canCreateHoppinPuntenDossiers) {
    createDossierInformation = [
      ...createDossierInformation,
      {
        dosdefKey: HOPPIN_PUNTEN_V1_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
      {
        dosdefKey: HOPPIN_PUNTEN_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }
  if (canCreateToegangkelijkeHaltesDossiers) {
    createDossierInformation = [
      ...createDossierInformation,
      {
        dosdefKey: TOEGANGKELIJKE_HALTES_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }
  if (canCreateVeiligeSchoolOmgevingDossiers) {
    createDossierInformation = [
      ...createDossierInformation,
      {
        dosdefKey: VEILIGE_SCHOOLOMGEVINGEN_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }
  if (canCreateVervoerOpMaatDossiers) {
    createDossierInformation = [
      ...createDossierInformation,
      {
        dosdefKey: VERVOER_OP_MAAT_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }
  if (canCreateZeroEmissieDossiers) {
    createDossierInformation = [
      ...createDossierInformation,
      {
        dosdefKey: ZERO_EMISSIE_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }

  return { createDossierInformation };
};

const mapDispatchToProps = {
  fetchDossierCreationToggles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(mowsubsidiesDashboard);
