export default {
  AANVRAAG_HOPPIN: "aanvraagHoppinpunten",
  SAMENVATTING_HOPPIN: "samenvattingHoppinpunten",
  AANVRAAG_HALTES: "aanvraagHaltes",
  AANVRAAG_SCHOOLOMGEVINGEN: "aanvraagSchoolomgevingen",
  CONTROLE_AANVRAAG_SCHOOLOMGEVINGEN: "controleAanvraagSchoolomgevingen",
  AFREKENING_SCHOOLOMGEVINGEN: "afrekeningSchoolomgevingen",
  CONTROLE_AFREKENING_SCHOOLOMGEVINGEN: "controleAfrekeningSchoolomgevingen",
  AANVRAAG_VERVOER_OP_MAAT: "aanvraagVervoerOpMaat",
  AANMELDING_ZERO_EMISSIE: "aanmeldingZeroEmissie",
  INSCHRIJVING_ZERO_EMISSIE: "inschrijvingZeroEmissie",
  ONTBREKENDE_INFO_ZERO_EMISSIE: "ontbrekendeInfoZeroEmissie",
  CONTROLE_AANMELDING_ZERO_EMISSIE: "controleAanmeldingZeroEmissie",
};
