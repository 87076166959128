import {
  form,
  navigation,
  SkryvFrontOfficeApp,
  translation,
} from "@skryv/core-react";
import "@skryv/core-react-vo";
import { registerDefaultRouting } from "@skryv/core-react-vo/src/config/routing";
import { registerDefaultTranslations } from "@skryv/core-react-vo/src/config/translations";

import translations from "../translations";
import "./theme/overrides.scss";

import customReduxStore from "./store";

import mowsubsidiesDashboard from "./customizations/components/mowsubsidiesDashboard";
import mowDossier from "./customizations/components/dossier/mowDossier";

import { layoutNames } from "@skryv/core-react/src/config/layouts";
import { pageNames } from "@skryv/core-react/src/config/pages";
import MowHeader from "./customizations/components/Header";
import MowFooter from "./customizations/components/Footer";

const customizedNavigation = registerDefaultRouting(navigation)
  .layout(layoutNames.FRONTOFFICE, {
    extend: true,
    views: {
      header: MowHeader,
      footer: MowFooter,
    },
  })
  .page(pageNames.DASHBOARD, {
    extend: true,
    views: {
      content: mowsubsidiesDashboard,
    },
  })
  .page(pageNames.DOSSIER, {
    extend: true,
    views: {
      content: mowDossier,
    },
  });

form.setFormConfig({
  showSectionWizard: true,
  showDocumentTitle: false,
  attachments: {
    acceptedMimeTypes: ["pdf"],
  },
});

const customizedTranslation = registerDefaultTranslations(translation)
  .locale("nl_BE") // set the language for the core components
  .add("nl_BE", translations.nl_BE); // make sure the custom client translations are also available

class App extends SkryvFrontOfficeApp {
  constructor() {
    super(customReduxStore);
  }
}

App.createSkryvApp()
  .customize(customizedNavigation)
  .customize(customizedTranslation)
  .customize(form)
  .initialize(document.getElementById("root"));
