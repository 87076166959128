import postscribe from "postscribe";

export function postscribePromise(selector, scriptSource) {
  return new Promise((resolve, reject) => {
    postscribe(
      selector,
      `<script type="text/javascript" src="${scriptSource}"></script>`,
      { done: resolve, error: reject },
    );
  });
}
