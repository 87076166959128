export const FETCH_DOSSIER_CREATION_TOGGLES = "FETCH_MOW_PROFILE";
export const FETCH_DOSSIER_CREATION_TOGGLES_RESPONSE = `${FETCH_DOSSIER_CREATION_TOGGLES}/RESPONSE`;
export const fetchDossierCreationToggles = () => {
  return {
    type: FETCH_DOSSIER_CREATION_TOGGLES,
    api: {
      url: "/api/mow/dossier-creation-toggles",
      method: "GET",
    },
  };
};

export const FETCH_BURGERPROFIEL_CONFIGURATION =
  "FETCH_BURGERPROFIEL_CONFIGURATION";
export const FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE = `${FETCH_BURGERPROFIEL_CONFIGURATION}/RESPONSE`;
export const FETCH_BURGERPROFIEL_CONFIGURATION_ERROR = `${FETCH_BURGERPROFIEL_CONFIGURATION}/ERROR`;
export const fetchBurgerprofielConfiguration = () => {
  return {
    type: FETCH_BURGERPROFIEL_CONFIGURATION,
    api: {
      url: "/api/burgerprofiel/configuration",
      method: "GET",
    },
  };
};
